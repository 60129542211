.addNewLayout-title {
  margin-bottom: 0px;
}

.addNewLayout-description {
  margin-top: 4px;
  margin-bottom: 4px;
}

.addNewLayout-description > *:not(:last-child) {
  padding-bottom: 4px;
}

.emptyState-icon {
  /*   height: 160px;
  width: 140px; */
}
