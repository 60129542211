[class*="hint--"] {
  position: relative;
  display: inline-block;
}
[class*="hint--"]:before,
[class*="hint--"]:after {
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  transition-delay: 0ms;
}
[class*="hint--"]:hover:before,
[class*="hint--"]:hover:after {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  transition-delay: 100ms;
}
[class*="hint--"]:before {
  content: "";
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001;
}
[class*="hint--"]:after {
  background: #071639;
  color: white;
  padding: rem(8px) rem(12px);
  font-size: rem(12px);
  line-height: 1.5;
  border-radius: rem(4px);
  letter-spacing: normal;
  white-space: nowrap;
  text-align: left;
  font-weight: normal;
  font-family: "Muli Bold";
}
[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}
[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}
[aria-label=""]:before,
[aria-label=""]:after,
[data-hint=""]:before,
[data-hint=""]:after {
  display: none !important;
}
.hint--top-left {
  &:before {
    border-top-color: #071639;
    margin-bottom: -11px;
    left: calc(50% - 6px);
  }
  &:after {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: 12px;
  }
  &:hover {
    &:before {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    &:after {
      -webkit-transform: translateX(-100%) translateY(-8px);
      -moz-transform: translateX(-100%) translateY(-8px);
      transform: translateX(-100%) translateY(-8px);
    }
  }
}
.hint--top-right {
  &:before {
    border-top-color: #071639;
    margin-bottom: -11px;
    left: calc(50% - 6px);
  }
  &:after {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    margin-left: -12px;
  }
  &:hover {
    &:before {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    &:after {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }
}
.hint--top {
  &:before {
    border-top-color: #071639;
    margin-bottom: -11px;
    left: calc(50% - 6px);
  }
  &:after {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  &:hover {
    &:before {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    &:after {
      -webkit-transform: translateX(-50%) translateY(-8px);
      -moz-transform: translateX(-50%) translateY(-8px);
      transform: translateX(-50%) translateY(-8px);
    }
  }
}
.hint--bottom-left {
  &:before {
    border-bottom-color: #071639;
    margin-top: -11px;
    left: calc(50% - 6px);
  }
  &:after {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: 12px;
  }
  &:hover {
    &:before {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
    &:after {
      -webkit-transform: translateX(-100%) translateY(8px);
      -moz-transform: translateX(-100%) translateY(8px);
      transform: translateX(-100%) translateY(8px);
    }
  }
}
.hint--bottom-right {
  &:before {
    border-bottom-color: #071639;
    margin-top: -11px;
    left: calc(50% - 6px);
  }
  &:after {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    margin-left: -12px;
  }
  &:hover {
    &:before {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
    &:after {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
  }
}
.hint--bottom {
  &:before {
    border-bottom-color: #071639;
    margin-top: -11px;
    left: calc(50% - 6px);
  }
  &:after {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  &:hover {
    &:before {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
    &:after {
      -webkit-transform: translateX(-50%) translateY(8px);
      -moz-transform: translateX(-50%) translateY(8px);
      transform: translateX(-50%) translateY(8px);
    }
  }
}
.hint--left {
  &:before {
    border-left-color: #071639;
    margin-right: -11px;
    margin-bottom: -6px;
  }
  &:after {
    margin-bottom: -14px;
  }
  &:hover {
    &:before {
      -webkit-transform: translateX(-8px);
      -moz-transform: translateX(-8px);
      transform: translateX(-8px);
    }
    &:after {
      -webkit-transform: translateX(-8px);
      -moz-transform: translateX(-8px);
      transform: translateX(-8px);
    }
  }
}
.hint--right {
  &:before {
    border-right-color: #071639;
    margin-left: -11px;
    margin-bottom: -6px;
  }
  &:after {
    margin-bottom: -14px;
  }
  &:hover {
    &:before {
      -webkit-transform: translateX(8px);
      -moz-transform: translateX(8px);
      transform: translateX(8px);
    }
    &:after {
      -webkit-transform: translateX(8px);
      -moz-transform: translateX(8px);
      transform: translateX(8px);
    }
  }
}
.hint--top:before,
.hint--top:after {
  bottom: 100%;
  left: 25%;
}
.hint--bottom:before,
.hint--bottom:after {
  top: 100%;
  left: 50%;
}
.hint--right:before,
.hint--right:after {
  left: 100%;
  bottom: 50%;
}
.hint--left:before,
.hint--left:after {
  right: 100%;
  bottom: 50%;
}
.hint--top-left:before,
.hint--top-left:after {
  bottom: 100%;
  left: 50%;
}
.hint--top-right:before,
.hint--top-right:after {
  bottom: 100%;
  left: 50%;
}
.hint--bottom-left:before,
.hint--bottom-left:after {
  top: 100%;
  left: 50%;
}
.hint--bottom-right:before,
.hint--bottom-right:after {
  top: 100%;
  left: 50%;
}
.hint--contain:after {
  white-space: nowrap;
  line-height: 1.5em;
}
.hint--small:after,
.hint--medium:after,
.hint--large:after {
  white-space: normal;
  line-height: 1.5em;
  word-wrap: break-word;
}
.hint--small {
  &:after {
    width: 80px;
  }
}
.hint--medium {
  &:after {
    width: 150px;
  }
}
.hint--large {
  &:after {
    width: 260px;
  }
}
.hint--error {
  &:after {
    background-color: #b34e4d;
    text-shadow: 0 -1px 0px #592726;
  }
  &.hint--top-left {
    &:before {
      border-top-color: #b34e4d;
    }
  }
  &.hint--top-right {
    &:before {
      border-top-color: #b34e4d;
    }
  }
  &.hint--top {
    &:before {
      border-top-color: #b34e4d;
    }
  }
  &.hint--bottom-left {
    &:before {
      border-bottom-color: #b34e4d;
    }
  }
  &.hint--bottom-right {
    &:before {
      border-bottom-color: #b34e4d;
    }
  }
  &.hint--bottom {
    &:before {
      border-bottom-color: #b34e4d;
    }
  }
  &.hint--left {
    &:before {
      border-left-color: #b34e4d;
    }
  }
  &.hint--right {
    &:before {
      border-right-color: #b34e4d;
    }
  }
}
.hint--warning {
  &:after {
    background-color: #c09854;
    text-shadow: 0 -1px 0px #6c5328;
  }
  &.hint--top-left {
    &:before {
      border-top-color: #c09854;
    }
  }
  &.hint--top-right {
    &:before {
      border-top-color: #c09854;
    }
  }
  &.hint--top {
    &:before {
      border-top-color: #c09854;
    }
  }
  &.hint--bottom-left {
    &:before {
      border-bottom-color: #c09854;
    }
  }
  &.hint--bottom-right {
    &:before {
      border-bottom-color: #c09854;
    }
  }
  &.hint--bottom {
    &:before {
      border-bottom-color: #c09854;
    }
  }
  &.hint--left {
    &:before {
      border-left-color: #c09854;
    }
  }
  &.hint--right {
    &:before {
      border-right-color: #c09854;
    }
  }
}
.hint--info {
  &:after {
    background-color: #3986ac;
    text-shadow: 0 -1px 0px #1a3c4d;
  }
  &.hint--top-left {
    &:before {
      border-top-color: #3986ac;
    }
  }
  &.hint--top-right {
    &:before {
      border-top-color: #3986ac;
    }
  }
  &.hint--top {
    &:before {
      border-top-color: #3986ac;
    }
  }
  &.hint--bottom-left {
    &:before {
      border-bottom-color: #3986ac;
    }
  }
  &.hint--bottom-right {
    &:before {
      border-bottom-color: #3986ac;
    }
  }
  &.hint--bottom {
    &:before {
      border-bottom-color: #3986ac;
    }
  }
  &.hint--left {
    &:before {
      border-left-color: #3986ac;
    }
  }
  &.hint--right {
    &:before {
      border-right-color: #3986ac;
    }
  }
}
.hint--success {
  &:after {
    background-color: #458746;
    text-shadow: 0 -1px 0px #1a321a;
  }
  &.hint--top-left {
    &:before {
      border-top-color: #458746;
    }
  }
  &.hint--top-right {
    &:before {
      border-top-color: #458746;
    }
  }
  &.hint--top {
    &:before {
      border-top-color: #458746;
    }
  }
  &.hint--bottom-left {
    &:before {
      border-bottom-color: #458746;
    }
  }
  &.hint--bottom-right {
    &:before {
      border-bottom-color: #458746;
    }
  }
  &.hint--bottom {
    &:before {
      border-bottom-color: #458746;
    }
  }
  &.hint--left {
    &:before {
      border-left-color: #458746;
    }
  }
  &.hint--right {
    &:before {
      border-right-color: #458746;
    }
  }
}
.hint--always:after,
.hint--always:before {
  opacity: 1;
  visibility: visible;
}
.hint--always {
  &.hint--top {
    &:before {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    &:after {
      -webkit-transform: translateX(-50%) translateY(-8px);
      -moz-transform: translateX(-50%) translateY(-8px);
      transform: translateX(-50%) translateY(-8px);
    }
  }
  &.hint--top-left {
    &:before {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    &:after {
      -webkit-transform: translateX(-100%) translateY(-8px);
      -moz-transform: translateX(-100%) translateY(-8px);
      transform: translateX(-100%) translateY(-8px);
    }
  }
  &.hint--top-right {
    &:before {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    &:after {
      -webkit-transform: translateY(-8px);
      -moz-transform: translateY(-8px);
      transform: translateY(-8px);
    }
  }
  &.hint--bottom {
    &:before {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
    &:after {
      -webkit-transform: translateX(-50%) translateY(8px);
      -moz-transform: translateX(-50%) translateY(8px);
      transform: translateX(-50%) translateY(8px);
    }
  }
  &.hint--bottom-left {
    &:before {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
    &:after {
      -webkit-transform: translateX(-100%) translateY(8px);
      -moz-transform: translateX(-100%) translateY(8px);
      transform: translateX(-100%) translateY(8px);
    }
  }
  &.hint--bottom-right {
    &:before {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
    &:after {
      -webkit-transform: translateY(8px);
      -moz-transform: translateY(8px);
      transform: translateY(8px);
    }
  }
  &.hint--left {
    &:before {
      -webkit-transform: translateX(-8px);
      -moz-transform: translateX(-8px);
      transform: translateX(-8px);
    }
    &:after {
      -webkit-transform: translateX(-8px);
      -moz-transform: translateX(-8px);
      transform: translateX(-8px);
    }
  }
  &.hint--right {
    &:before {
      -webkit-transform: translateX(8px);
      -moz-transform: translateX(8px);
      transform: translateX(8px);
    }
    &:after {
      -webkit-transform: translateX(8px);
      -moz-transform: translateX(8px);
      transform: translateX(8px);
    }
  }
}
.hint--rounded {
  &:after {
    border-radius: 6px;
  }
}
.hint--no-animate:before,
.hint--no-animate:after {
  -webkit-transition-duration: 0ms;
  -moz-transition-duration: 0ms;
  transition-duration: 0ms;
}
.hint--bounce:before,
.hint--bounce:after {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}
