/**
Typography
*/

.headingDisplay {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.2;
  font-family: "Muli Regular";
}

.subtitle {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: "Muli Regular";
}

.heading {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.2;
  font-weight: normal;
  font-family: "Muli ExtraBold";
}

.caption {
  font-family: "Muli ExtraBold";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
}

.hd1 {
  font-size: rem(80px);
  @extend .headingDisplay;
}

.hd2 {
  font-size: rem(72px);
  @extend .headingDisplay;
}
.hd3 {
  font-size: rem(64px);
  @extend .headingDisplay;
}
.hd4 {
  font-size: rem(56px);
  @extend .headingDisplay;
}
.hd5 {
  font-size: rem(48px);
  @extend .headingDisplay;
}
.hd6 {
  font-size: rem(40px);
  @extend .headingDisplay;
}

.h1 {
  @extend .heading;
  font-size: rem(40px);
}
.h2 {
  @extend .heading;
  font-size: rem(32px);
}
.h3 {
  @extend .heading;
  font-size: rem(28px);
}
.h4 {
  @extend .heading;
  font-size: rem(24px);
}
.h5 {
  @extend .heading;
  font-size: rem(20px);
}
.h6 {
  @extend .heading;
  font-size: rem(16px);
}

.subtitle1 {
  @extend .subtitle;
  font-size: rem(24px);
}

.subtitle2 {
  @extend .subtitle;
  font-size: rem(20px);
}

.caption1 {
  @extend .caption;
  letter-spacing: rem(2px);
  font-size: rem(16px);
  text-transform: uppercase;
}

.caption2 {
  @extend .caption;
  letter-spacing: rem(2px);
  font-size: rem(14px);
  text-transform: uppercase;
}

.caption3 {
  @extend .caption;
  letter-spacing: rem(1px);
  font-size: rem(12px);
  text-transform: uppercase;
}

.caption4 {
  @extend .caption;
  letter-spacing: rem(1px);
  font-size: rem(11px);
  text-transform: uppercase;
}

.base-text {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-lg {
  font-size: rem(20px);
}
.text-md {
  font-size: rem(16px);
}
.text-sm {
  font-size: rem(14px);
}
.text-xs {
  font-size: rem(12px);
}

.text-regular {
  font-weight: normal;
  font-family: "Muli Regular";
}
.text-medium {
  font-weight: normal;
  font-family: "Muli Bold";
}
.text-bold {
  font-weight: normal;
  font-family: "Muli ExtraBold";
}

/* Light theme text colors */
.light-hd1 {
  color: #071639;
}

.light-hd2 {
  color: #071639;
}
.light-hd3 {
  color: #071639;
}
.light-hd4 {
  color: #071639;
}
.light-hd5 {
  color: #071639;
}
.light-hd6 {
  color: #071639;
}

.light-h1 {
  color: #071639;
}
.light-h2 {
  color: #071639;
}
.light-h3 {
  color: #071639;
}
.light-h4 {
  color: #071639;
}
.light-h5 {
  color: #071639;
}
.light-h6 {
  color: #071639;
}

.light-subtitle1 {
  color: #6a7388;
}

.light-subtitle2 {
  color: #6a7388;
}

.light-caption1 {
  color: #9ca2b0;
}

.light-caption2 {
  color: #9ca2b0;
}

.light-caption3 {
  color: #9ca2b0;
}

.light-caption4 {
  color: #9ca2b0;
}

.light-text-primary {
  @extend .base-text;
  color: #071639;
}
.light-text-secondary {
  @extend .base-text;
  color: #394561;
}
.light-text-tertiary {
  @extend .base-text;
  color: #6a7388;
}
.light-text-muted {
  @extend .base-text;
  color: #9ca2b0;
}
.light-text-link {
  @extend .base-text;
  color: #1e5eff;
}
.light-text-error {
  @extend .base-text;
  color: #f0142f;
}
.light-text-success {
  @extend .base-text;
  color: #15a251;
}
.light-text-warning {
  @extend .base-text;
  color: #d36133;
}

/* Dark theme text colors */

.dark-hd1 {
  color: #ffffff;
}

.dark-hd2 {
  color: #ffffff;
}
.dark-hd3 {
  color: #ffffff;
}
.dark-hd4 {
  color: #ffffff;
}
.dark-hd5 {
  color: #ffffff;
}
.dark-hd6 {
  color: #ffffff;
}

.dark-h1 {
  color: #ffffff;
}
.dark-h2 {
  color: #ffffff;
}
.dark-h3 {
  color: #ffffff;
}
.dark-h4 {
  color: #ffffff;
}
.dark-h5 {
  color: #ffffff;
}
.dark-h6 {
  color: #ffffff;
}

.dark-subtitle1 {
  color: #9ba1af;
}

.dark-subtitle2 {
  color: #9ba1af;
}

.dark-caption1 {
  color: #6a7388;
}

.dark-caption2 {
  color: #6a7388;
}

.dark-caption3 {
  color: #6a7388;
}

.dark-caption4 {
  color: #6a7388;
}

.dark-text-primary {
  @extend .base-text;
  color: #ffffff;
}
.dark-text-secondary {
  @extend .base-text;
  color: #cdd0d7;
}
.dark-text-tertiary {
  @extend .base-text;
  color: #9ba1af;
}
.dark-text-muted {
  @extend .base-text;
  color: #6a7388;
}
.dark-text-link {
  @extend .base-text;
  color: #1e5eff;
}
.dark-text-error {
  @extend .base-text;
  color: #f0142f;
}
.dark-text-success {
  @extend .base-text;
  color: #15a251;
}
.dark-text-warning {
  @extend .base-text;
  color: #d36133;
}
