@import "./sidebar/sidebar.scss";
@import "./manageTeams/newTeamView.scss";
@import "./manageUsers/editAdminAccess.scss";
@import "./manageUsers/userOverview.scss";
@import "./components/layout.scss";
@import "./components/toaster.scss";
@import "./components/passwordInput.scss";

.container-full-height {
  min-height: 100vh !important;
}

.vertical-dropdown {
  margin-top: 40px !important;
  margin-right: -20px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.section-border-bottom {
  border-bottom: 1px solid #e6e7eb;
}

.section-border-left {
  border-left: 1px solid #e6e7eb;
}
/* 
.fill {
  min-height: 100%;
  height: 100%;
} */

.z-index-top {
  z-index: 100;
}

.full-height {
  height: 100vh;
}
