/**
 Styling to enable independent scrolling of sidebar/ main content
 **/
 
 /*
 body {
    overflow-y:hidden;
   }
  
   .baselayout-main {
    overflow-y: scroll;
    height: 90vh;
   }

   /
   

   /**
   *** Tooltips are currently hidden due to the overflow property, the answer may be in position:absolute
   *** https://stackoverflow.com/questions/39146047/display-tooltip-when-container-overflow-is-hidden
   **/

   #sidebarMenu {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;
  }
  
  #sidebarMenu::-webkit-scrollbar {
  width: 6px;
  }
  

  #sidebarMenu::-webkit-scrollbar-thumb {
  background: #CDD0D7;
  border-radius: 6px;
  }