.input-group-text {
  background-color: white !important;
  color: #9ca3af;
}

.input-group {
  //margin-top: rem(6px);
}
.form-control-icon-right {
  border-right: transparent !important;
}

.form-control-icon-left {
  border-left: transparent !important;
}

.box-shadow-focus-effect {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-radius: rem(6px);
}

.regular-group:focus-within {
  @extend .box-shadow-focus-effect;
}

.form-control:focus ~ .input-group-text {
  color: #212529;
  background-color: #fff;
  outline: 0;
}

.form-floating:focus-within {
  @extend .box-shadow-focus-effect;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #d1d5db !important;
}

.warning-focus {
  border-color: $error !important;
  box-shadow: 0 0 0 0.25rem #fde7ea;
  box-sizing: border-box;
  border-radius: rem(6px);
  border-style: solid;
  border-width: rem(1px);
}

.number-input-warning {
  @extend .warning-focus;
  box-shadow: none;
}

.warning-message {
  @extend .input-text-sm;
  margin: rem(9px) 0 0 rem(6px);
  //font-family: Inter;
  color: $error !important;
}

.text-styling {
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #394561;
}

.input-text-lg {
  //font-family: Inter-Medium !important;
  font-size: rem(16px) !important;
  font-weight: 500 !important;
  @extend .text-styling;
}

.input-text-md {
  //font-family: Inter-Medium !important;
  font-size: rem(16px) !important;
  font-weight: 500 !important;
  @extend .text-styling;
}

.input-text-sm {
  //font-family: Inter-Medium !important;
  font-size: rem(14px) !important;
  font-weight: 500 !important;
  @extend .text-styling;
}

.form-label {
  font-size: rem(14px);
  //font-family: Inter-Bold;
  font-weight: 500;
  @extend .text-styling;
  color: #374151;
}

.form-control-lg {
  padding: rem(12px);
}

.form-control-md {
  padding: rem(8px) rem(12px);
}
.form-control-sm {
  padding: rem(7px) rem(12px) rem(8px);
}

textarea {
  padding: rem(12px);
  border-radius: rem(6px);
  border: solid 1px #d1d5db;
  background-color: #ffffff;
  margin: rem(6px) rem(0) rem(0);
  min-height: rem(68px) !important;
}

/**
File Upload
**/
.fileInput {
  display: none !important;
}
.media-input-group {
  border-style: dashed;
  border-radius: rem(6px);
  cursor: pointer;
  border-color: #9ca3af;
  color: #1e5eff;
  border-width: thin;
}

.media-input:read-only {
  background-color: white;
  color: #1e5eff !important;
  cursor: pointer;
  outline: none;
}

.media-input::placeholder {
  @extend .input-text-lg;
  color: #1e5eff !important;
}
//Number Input section custom css
.button-group {
  border: solid 1px #d1d5db;
  border-radius: 6px;
  background-color: #ffffff;
}

.border-less {
  border: 0px;
}

.not-editable:read-only {
  text-align: center !important;
  background-color: white;
}

.form-control-active {
  border-color: $primary !important;
  outline: 0;
  border-radius: rem(6px);
  box-shadow: 0 0 0 0.2rem $primary;
}

.hide-input-border-right {
  border-right: none !important;
}

/* Password Input */
.password-input {
  width: 95%;
  border: none;
  outline: none;
}

.invisible-button {
  background: white;
  border: none;
}

.password-border {
  border: 1px solid #ced4da;
}
