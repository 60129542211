.tag-default {
  display: flex;
}

/**
  White background button with blue font color.
  Similar to primary button but with a white background
*/
.tag-primary-white-background-blue-text {
  background-color: #ffffff;
  color: #1e5eff;
  border-color: #d2dfff;
}

.tag-primary-white-background-blue-text:hover {
  background-color: #ffffff;
  color: #1e5eff;
  border-color: #d2dfff;
}

.tag-primary-white-background-blue-text:active {
  outline: none !important;
  background-color: #ffffff;
  box-shadow: none;
}

.tag-primary-white-background-blue-text:focus {
  outline: none !important;
  background-color: #ffffff;
  box-shadow: none;
}

.tag-primary-white-background-blue-text:disabled {
  background-color: #ffffff;
  color: #b5b9c4;
  border-color: #e6e8eb;
}

/**
  Primary tag states in it's secondary color.
*/
.tag-secondary-light-blue-background-blue-text {
  color: #1e5eff;
  background-color: #f4f7ff;
  border-color: #e9efff;
}

.tag-secondary-light-blue-background-blue-text:hover {
  background-color: #f4f7ff;
  color: #1e5eff;
  border-color: #e9efff;
}

.tag-secondary-light-blue-background-blue-text:active {
  background-color: #f4f7ff;
  color: #1e5eff;
  box-shadow: none;
}

.tag-secondary-light-blue-background-blue-text:disabled {
  background-color: #d7dbec;
  box-shadow: none;
}

.tag-secondary-light-blue-background-blue-text:focus {
  background-color: #f4f7ff;
  border-color: #e9efff;
  color: #1e5eff;
  box-shadow: none;
}
