.note-container {
  padding: 16px;
  border-radius: 4px;
  background-color: #fff8f1;
}

.note-info-icon {
  color: #d36133;
  margin-right: rem(12px);
}
