.sideBarFooter {
  position: fixed;
  bottom: 0;
  margin-bottom: 32px;
}

.sideMenuOpen {
  width: 268px;
}

.sideBarLink {
  text-decoration: none;
}