.page-header {
  padding: rem(26px) rem(32px) rem(26px) rem(32px);
  height: rem(81px);
  background-color: #ffffff;
}

.vertical-divider {
  width: rem(1px);
  height: rem(12px);
  margin: rem(12px) rem(12px) rem(14px);
  background-color: #cdd0d7;
}

.notification-color {
  color: #838a9c;
}

.page-header-border-bottom {
  height: rem(1px);
  background-color: #e6e7eb;
  border: 1px;
}

.page-header > svg:first-of-type {
  margin-right: rem(24px);
  color: #6a7388;
  margin-bottom: rem(5px);
  cursor: pointer;
}

//Section Header

.section-header {
  padding: rem(24px) rem(31px) rem(16px) rem(31px);
  width: 100%;
  background-color: #ffffff;
}

.section-sibling + .section-sibling {
  padding-top: rem(12px);
}

.section-sibling {
  color: red;
}

.section-header > .section-sibling:first-child {
  color: #6a7388;
  cursor: pointer;
}

.section-header > .section-sibling:first-child > svg {
  margin-right: rem(4px);
}

// Individual page sections

.section-padding-top {
  padding-top: rem(12px);
}

.section-padding-bottom {
  padding-bottom: rem(12px);
}

//Individual page section titles
.section-title {
  padding-top: rem(24px);
  padding-bottom: rem(18px);
}

//Section Divider
.section-divider {
  background-color: #e6e7eb;
  width: 100%;
  height: rem(1px);
  margin-top: rem(8px);
  margin-bottom: rem(8px);
}
