.icon-box {
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  border-style: solid;
  color: lightgrey;
}

.icon-box-dark {
  background-color: white;
  color: black;
}

.icon-box-light {
  @extend .bg-dark;
  color: white;
}

.icon-box-preview {
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  background-color: lightgray;
  border-radius: 5px 5px 5px 5px;
  color: white;
}

.icon-box-section {
  margin-top: 7px;
  margin-bottom: 7px;
}
