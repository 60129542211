.misc-manage-columns > :nth-child(2) {
  margin-bottom: rem(8px);
}

.misc-manage-columns > :nth-child(3) {
  margin-bottom: rem(24px);
}

.misc-manage-columns .geometric-icon {
  margin-bottom: rem(24px);
}

.geometric-icon {
  width: rem(80px);
  height: rem(80px);
  object-fit: contain;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 50%;
}

.geometric-icon > svg {
  display: block;
  margin: 0 auto;
}

//Misc Add Member dropdown
.dropdown-menu-custom {
  @extend .dropdown-menu;
  top: auto;
  margin-top: rem(48px) !important;
}

.li-avatar-name {
  margin-left: rem(12px);
}

.li-bullet-point {
  height: rem(4px);
  width: rem(4px);
  margin: rem(4px);
  background-color: #cdd0d7;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
