.stats-section {
  padding-top: rem(20px);
  padding-bottom: rem(20px);
}

.stats-section-row {
  background-color: #f7f8f9;
}

.stats-section > span:first-child {
  margin-bottom: rem(0px);
}

.stat-divider {
  background: linear-gradient(#cdd0d7, #cdd0d7) right / 1px 50%;
  background-repeat: no-repeat;
}

.col-2 > .stats-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
