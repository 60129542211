@use "sass:math";

@import "bootstrap/scss/bootstrap";
@import "./variables";

@font-face {
  font-family: "Muli Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Muli Regular"), url("../static/fonts/Muli-Regular.woff") format("woff");
}

@font-face {
  font-family: "Muli SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Muli SemiBold"), url("../static/fonts/Muli-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Muli Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Muli Bold"), url("../static/fonts/Muli-Bold.woff") format("woff");
}

@font-face {
  font-family: "Muli ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Muli ExtraBold"), url("../static/fonts/Muli-ExtraBold.woff") format("woff");
}

body {
  font-family: "Muli Regular", sans-serif;
}

div.ellipsize-text,
.ellipsize-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

div.width-95-pc,
.width-95-pc {
  width: 95%;
  //Added by David 22/03/2022
  margin-right: auto;
}

/* Configuration file */
$primary: #1e5eff;
$secondary: #d9e4ff;
$outline: #ffffff;
$heading: #051230;
$base: #6b7280;
$error: #f0142f;
$regular: #374151;
$muted: #9ca3af;
$success: #15a251;
$warning: #d36133;
$active: #1745bb;
$link: #1e5eff;
$disabled: #d7dbec;
$border: #d1d5db;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "outline": $outline,
);

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "outline": $outline,
  "heading": $heading,
  "base": $base,
  "error": $error,
  "regular": $regular,
  "muted": $muted,
  "success": $success,
  "warning": $warning,
);

$solid-notification: (
  "primary": #336dff,
  "success": #25aa5e,
  "error": #f34359,
  "alert": #f99600,
  "neutral": #475569,
);

$light-notification: (
  "primary": #ecf2ff,
  "success": #e4f4eb,
  "error": #fde7ea,
  "alert": #fff8f1,
  "neutral": #f1f5f9,
);

$notification-position: (
  "center": "center",
  "left": "left",
  "right": "right",
);

$alert-background-color: (
  "primary": #ecf2ff,
  "success": #e4f4eb,
  "error": #fde7ea,
  "alert": #fff8f1,
  "neutral": #f1f5f9,
);

$alert-body-color: (
  "primary": #1e5eff,
  "success": #15a251,
  "error": #f0142f,
  "alert": #d36133,
  "neutral": #5a607f,
);

$html-font-size: 16px;

@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

@each $name, $variable in $alert-body-color {
  .alert-heading-#{$name} {
    color: #{$variable} !important;
    @extend .default-font-styling;
    font-weight: 700;
    //font-family: $mediumFont;
  }
  .alert-body-#{$name} {
    color: #{$variable} !important;
    @extend .default-font-styling;
    font-size: rem(14px);
    //font-family: $regularFont;
  }
  .alert-action-#{$name} {
    color: #{$variable} !important;
    @extend .default-font-styling;
    font-size: rem(14px);
    font-weight: 700;
    //font-family: $mediumFont;
  }
  .alert-section-#{$name} {
    background-color: map-get($alert-background-color, $name);
    color: #{$variable};
    border-radius: rem(6px);
  }
}

@each $name, $variable in $colors {
  .#{$name} {
    color: #{$variable};
  }
}

@each $name, $variable in $colors {
  .bg-#{$name} {
    background-color: #{$variable} !important;
  }
}

@each $name, $variable in $solid-notification {
  .solid-#{$name}-notification {
    background-color: #{$variable} !important;
    font-size: rem(16px) !important;
    font-weight: 500;
    @extend .default-font-styling;
    color: $outline;
  }
}

@each $lname, $lvariable in $light-notification {
  .light-#{$lname}-notification {
    background-color: #{$lvariable};
    font-size: rem(16px) !important;
    font-weight: 500;
    @extend .default-font-styling;
    color: map-get($solid-notification, $lname);
  }
}

@each $name, $variable in $notification-position {
  .notification-#{$name} {
    text-align: #{$variable};
  }
}

.default-font-styling {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.icon {
  margin-left: rem(6px);
  margin-right: rem(6px);
}

/**
Added 22/03/2022 by David to change padding on Text Area  component
**/
.form-floating > .form-control {
  padding-top: rem(12px);
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: rem(12px) !important;
}
