.list-dropdown-button > * {
  margin-right: rem(4px);
}

.list-dropdown-button > span > svg {
  margin-left: rem(4px);
}
.list-dropdown {
  padding: rem(0px);
  cursor: pointer;
}

.list-dropdown-menu {
  min-width: auto !important;
}

.custom-list-items {
  // padding-top: rem(16px);
  scrollbar-width: thin;
  max-height: 70vh !important;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: rem(20px);
}

.list-background {
  padding-top: rem(24px);
  padding-left: rem(32px);
  //padding-right: rem(32px);
  width: rem(334px);
}

.custom-item {
  list-style-type: none;
  border: none;
  border-radius: rem(4px);
}

.custom-list-items > .custom-item {
  padding: rem(12px);
  list-style-type: none;
  cursor: pointer;
  border: none;
  border-radius: rem(4px);
  //margin-right: rem(16px);
}

.custom-list-items .custom-item:active {
  background-color: #e8eeff;
}

.custom-item-active {
  background-color: #e8eeff;
}

.custom-item-inactive {
  background-color: #ffffff;
}

.custom-item-active .item-text > div:first-of-type {
  color: #1e5eff;
}

.custom-item > span > svg {
  margin-right: rem(12px);
}

.custom-item > img {
  margin-right: rem(12px);
}

.custom-list-items > .custom-item:hover div:first-of-type {
  color: #1e5eff;
}

.item-text {
  vertical-align: super;
  word-break: break-all;
}

.custom-item > .team-avatar {
  margin-right: rem(12px);
}

.custom-list-items::-webkit-scrollbar {
  width: 4px;
  height: 500px;
  border-radius: 2px;
  background-color: #ffffff;
}

.custom-list-items::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2px;
  background-color: #cdd0d7;
  height: 150px;
}

.custom-list-items::-webkit-scrollbar-track {
  height: 150px;
}

.custom-list-items::-webkit-scrollbar-thumb:hover {
  background-color: #6a7388;
}
