.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 50% !important;
  height: 100% !important;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

@media (min-width: 576px) {
  .panel-dialog {
    max-width: 50% !important;
  }
}

.panel-dialog {
  height: 100%;
}

.panel {
  border-radius: 0;
  border: none;
  display: flex;
  flex-direction: column;
}
.panel-content {
  padding: rem(32px);
}

.panel-header-left {
  float: left;
}

.panel-header-right {
  float: right;
}
.panel-header-button {
  color: #6a7388;
  cursor: pointer;
}

.panel-header-clear {
  clear: left;
}

.panel-header {
  margin-bottom: rem(12px);
}
.panel-description {
  margin-bottom: rem(20px);
}

.panel-footer {
  background-color: #f7f8f9;
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  padding-right: rem(32px);
  padding-left: rem(32px);
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}
