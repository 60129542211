@use "sass:map";

.baselayout {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  // min-width: max-content;

  &-header {
    flex: 0 1 100%;
  }

  &-sidebar {
    //display: flex;
    flex-direction: column;
    //min-width: 1px;
    //min-height: calc(100vh - $topbar-height);
    background-color: map.get($colors, "dark-bg");

    & > * {
      /**Removed positoon sticky to fix FS-692 & FS-709 bugs**/
      //position: sticky;
      top: 0;
      align-self: flex-start;
      height: auto;
    }
  }

  &-main {
    flex: 1;
  }
}
