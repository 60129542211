.password-input {
  width: 95%;
  border: none;
  outline: none;
}

.invisible-button {
  background: white;
  border: none;
}

.password-border {
  border: 1px solid #ced4da;
}
