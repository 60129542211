/*
  Add this to all div's within the element which has the segment class.
  This will apply padding bottom to all children except the last.
*/
.segment > div:not(:last-child) {
  padding-bottom: 20px;
}

/*
  Apply appropriate spacing between custom admin description text & children below
*/
.custom-admin-children > div:first-child {
  padding-top: 20px;
}

/*
  Apply appropriate spacing between custom admin children excluding the last child.
*/
.custom-admin-children > div:not(:last-child) {
  padding-bottom: 20px;
}
