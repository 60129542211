//Tables
.header-light {
  @extend .table-light;
}

.header-solid {
  @extend .table-secondary;
}

.chevron-spacing {
  margin-left: rem(8px);
}

.xpage {
  background-color: #f9fafb;
}

.xheader th {
  border-style: none !important;
  padding-left: rem(24px) !important;
  padding-right: rem(24px) !important;
}

.xheader span {
  text-transform: uppercase;
  font-size: rem(12px) !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: rem(1px);
  line-height: 1.5;
  color: #9ca3af !important;
}

.xrow {
  background-color: #ffffff;
  height: rem(52px);
  line-height: rem(52px);
}

.xrow td {
  border-style: none !important;
  padding-left: rem(24px) !important;
  padding-right: rem(24px) !important;
}

.pagination-footer {
  margin-bottom: rem(54px);
}

.table-wrapper {
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 #e6e9f4, 0 3px 10px 0 #e6e9f4;
  overflow: hidden;
}
