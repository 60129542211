// Start of switch/toggle section
.form-switch.custom-switch-md .form-check-input {
  width: rem(44px);
  height: rem(24px);
  padding: rem(4px) rem(24px) rem(4px) rem(4px);
  border-radius: rem(16px);
  background-color: #9ca2b0;
  border: none;
}

/* .form-switch.custom-switch-md .form-check-input:focus {
  background-color: #9ca2b0;
  box-shadow: 0 0 2px 2px #6a7388 !important;
} */

.form-switch.custom-switch-lg .form-check-input {
  width: rem(48px);
  height: rem(28px);
  padding: rem(4px) rem(24px) rem(4px) rem(4px);
  border-radius: rem(16px);
  background-color: #9ca2b0;
  border: none;
}

.form-switch.custom-switch-sm .form-check-input {
  width: rem(36px);
  height: rem(20px);
  padding: rem(4px) rem(20px) rem(4px) rem(4px);
  border-radius: rem(16px);
  background-color: #9ca2b0;
  border: none;
}

.form-switch .form-check-input {
  background-position: left center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  background-color: #89abff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-color: #1e5eff;
  background-image: url("../../static/icons/check-circle.png");
  background-position: right center;
}

.form-switch .form-check-input:disabled:checked {
  background-color: #bbceff;
  background-image: url("../../static/icons/check-circle.png");
  background-position: right center;
  opacity: 1;
}

.form-check-input {
  @extend .form-check-input;
  width: rem(20px);
  height: rem(20px);
}
