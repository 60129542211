.sidebar-width-expanded {
  max-width: 260px;
}
.sidebar-width-minimised {
  max-width: 80px;
}

.sidebar-offset-margin-expanded {
  margin-left: 260px;
}

.sidebar-offset-margin-minimised {
  margin-left: 80px;
}
