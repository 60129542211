/*
  This class makes text break and flow to next line
  when too long.
*/
.text-break {
  word-break: break-all;
}

/*
  This class adds a hand cursor when hover over element
*/
.hand-pointer {
  cursor: pointer;
}

/*
  This class adds negative margins top & bottom to element.
*/
.negative-margin-12 {
  margin-top: -12px;
  margin-bottom: -12px;
}
