.avatar {
  width: rem(32px);
  height: rem(32px);
  margin-right: rem(18px);
  border-radius: rem(8px);
}

.initialAvatar {
  margin: rem(4px);
  text-align: center;
  font-weight: 600;
}

.imageAvatar {
  width: rem(32px);
  height: rem(32px);
  border-radius: rem(8px);
}

.profileAvatarImage {
  border-radius: 50% !important;
  width: rem(40px);
  height: rem(40px);
}

//Team Avatar 22/04/2021
.team-avatar-md {
  min-width: rem(40px);
  height: rem(40px);
  border-radius: rem(10px);
  font-size: rem(16px);
}
.team-avatar-xs {
  min-width: rem(30px);
  height: rem(30px);
  border-radius: rem(10px);
  font-size: rem(12px);
}

//User Avatar 05/05/2021
.user-avatar-md {
  width: rem(40px);
  height: rem(40px);
}
.user-avatar-xs {
  width: rem(30px);
  height: rem(30px);
}

$avatar-backgrounds: (
  "grey": "#515C74",
  "yellow": #ffa828,
  "purple": #65376a,
  "blue": #4f58df,
  "aqua": #00b8da,
  "red": #ff562e,
  "lightBlue": #2684fe,
  "lightPurple": #895ecc,
  "green": #36b37f,
  "lime": #74c05e,
  "lightAqua": #4ac6c6,
  "orange": #ff761b,
  "navy": #292787,
);

@each $name, $variable in $avatar-backgrounds {
  .#{$name}-avatar-background {
    background-color: #{$variable};
  }
}

$avatar-colors: (
  "yellow": #ffa828,
  "purple": #65376a,
  "blue": #4f58df,
  "aqua": #00b8da,
  "red": #ff562e,
  "lightBlue": #2684fe,
  "lightPurple": #895ecc,
  "green": #36b37f,
  "lime": #74c05e,
  "lightAqua": #4ac6c6,
  "orange": #ff761b,
  "navy": #292787,
);

@each $name, $variable in $avatar-colors {
  .#{$name}-avatar-color {
    color: #{$variable};
  }
}
