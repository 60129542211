div.overwrite-bs-gutter-x,
overwrite-bs-gutter-x {
  --bs-gutter-x: 2rem;
}

div.container-fluid {
  --bs-gutter-x: 1rem;
}

div.row {
  margin: auto;
}

div.padding-1rem,
.padding-1rem {
  padding: 1rem;
}

div.padding-2rem,
.padding-2rem {
  padding: 2rem;
}
