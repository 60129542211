.toast-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 32px;
  right: 32px;
  // TODO: need a z-index scheme to manage layering
  z-index: 10000;

  & > div {
    margin-bottom: 8px;
  }

  & > button {
    margin-top: 8px;
  }
}

/* animation states used with react-transition-group */
$toast-transition: all 350ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */

.toast-message-enter {
  opacity: 0;
  transform: translateX(100px);
}

.toast-message-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: $toast-transition;
}

.toast-message-exit {
  opacity: 1;
  transform: translateY(0);
}

.toast-message-exit-active {
  opacity: 0;
  transform: translateY(-60px);
  transition: $toast-transition;
}
