@font-face {
  font-family: 'fundrecs';
  src: url('fundrecs.eot?37672438');
  src: url('fundrecs.eot?37672438#iefix') format('embedded-opentype'),
    url('fundrecs.woff2?37672438') format('woff2'),
    url('fundrecs.woff?37672438') format('woff'),
    url('fundrecs.ttf?37672438') format('truetype'),
    url('fundrecs.svg?37672438#fundrecs') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'fundrecs';
    src: url('fundrecs.svg?37672438#fundrecs') format('svg');
  }
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fundrecs';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-icon-academic-cap-filled:before {
  content: '\e800';
} /* '' */
.icon-icon-academic-cap-outlined:before {
  content: '\e801';
} /* '' */
.icon-icon-adjustments-filled:before {
  content: '\e802';
} /* '' */
.icon-icon-adjustments-outlined:before {
  content: '\e803';
} /* '' */
.icon-icon-annotation-filled:before {
  content: '\e804';
} /* '' */
.icon-icon-annotation-outlined:before {
  content: '\e805';
} /* '' */
.icon-icon-archive-filled:before {
  content: '\e806';
} /* '' */
.icon-icon-archive-outlined:before {
  content: '\e807';
} /* '' */
.icon-icon-arrow-circle-down-filled:before {
  content: '\e808';
} /* '' */
.icon-icon-arrow-circle-down-outlined:before {
  content: '\e809';
} /* '' */
.icon-icon-arrow-circle-left-filled:before {
  content: '\e80a';
} /* '' */
.icon-icon-arrow-circle-left-outlined:before {
  content: '\e80b';
} /* '' */
.icon-icon-arrow-circle-right-filled:before {
  content: '\e80c';
} /* '' */
.icon-icon-arrow-circle-right-outlined:before {
  content: '\e80d';
} /* '' */
.icon-icon-arrow-circle-up-filled:before {
  content: '\e80e';
} /* '' */
.icon-icon-arrow-circle-up-outlined:before {
  content: '\e80f';
} /* '' */
.icon-icon-arrow-down:before {
  content: '\e810';
} /* '' */
.icon-icon-arrow-left:before {
  content: '\e811';
} /* '' */
.icon-icon-arrow-narrow-down:before {
  content: '\e812';
} /* '' */
.icon-icon-arrow-narrow-left:before {
  content: '\e813';
} /* '' */
.icon-icon-arrow-narrow-right:before {
  content: '\e814';
} /* '' */
.icon-icon-arrow-narrow-up:before {
  content: '\e815';
} /* '' */
.icon-icon-arrow-right:before {
  content: '\e816';
} /* '' */
.icon-icon-arrow-up:before {
  content: '\e817';
} /* '' */
.icon-icon-arrows-expand:before {
  content: '\e818';
} /* '' */
.icon-icon-at-symbol:before {
  content: '\e819';
} /* '' */
.icon-icon-backspace-filled:before {
  content: '\e81a';
} /* '' */
.icon-icon-backspace-outlined:before {
  content: '\e81b';
} /* '' */
.icon-icon-badge-check-filled:before {
  content: '\e81c';
} /* '' */
.icon-icon-badge-check-outlined:before {
  content: '\e81d';
} /* '' */
.icon-icon-ban-outlined:before {
  content: '\e81e';
} /* '' */
.icon-icon-beaker-filled:before {
  content: '\e81f';
} /* '' */
.icon-icon-beaker-outlined:before {
  content: '\e820';
} /* '' */
.icon-icon-bell-filled:before {
  content: '\e821';
} /* '' */
.icon-icon-bell-outlined:before {
  content: '\e822';
} /* '' */
.icon-icon-book-open-filled:before {
  content: '\e823';
} /* '' */
.icon-icon-book-open-outlined:before {
  content: '\e824';
} /* '' */
.icon-icon-bookmark-alt-filled:before {
  content: '\e825';
} /* '' */
.icon-icon-bookmark-alt-outlined:before {
  content: '\e826';
} /* '' */
.icon-icon-bookmark-filled-filled:before {
  content: '\e827';
} /* '' */
.icon-icon-bookmark-outlined:before {
  content: '\e828';
} /* '' */
.icon-icon-briefcase-filled:before {
  content: '\e829';
} /* '' */
.icon-icon-briefcase-outlined:before {
  content: '\e82a';
} /* '' */
.icon-icon-cake-filled:before {
  content: '\e82b';
} /* '' */
.icon-icon-cake-outlined:before {
  content: '\e82c';
} /* '' */
.icon-icon-calculator-filled:before {
  content: '\e82d';
} /* '' */
.icon-icon-calculator-outlined:before {
  content: '\e82e';
} /* '' */
.icon-icon-calendar-filled:before {
  content: '\e82f';
} /* '' */
.icon-icon-calendar-outlined:before {
  content: '\e830';
} /* '' */
.icon-icon-camera-filled:before {
  content: '\e831';
} /* '' */
.icon-icon-camera-outlined:before {
  content: '\e832';
} /* '' */
.icon-icon-cash-filled:before {
  content: '\e833';
} /* '' */
.icon-icon-cash-outlined:before {
  content: '\e834';
} /* '' */
.icon-icon-chart-bar-filled:before {
  content: '\e835';
} /* '' */
.icon-icon-chart-bar-outlined:before {
  content: '\e836';
} /* '' */
.icon-icon-chart-pie-filled:before {
  content: '\e837';
} /* '' */
.icon-icon-chart-pie-outlined:before {
  content: '\e838';
} /* '' */
.icon-icon-chart-square-bar-filled:before {
  content: '\e839';
} /* '' */
.icon-icon-chart-square-bar-outlined:before {
  content: '\e83a';
} /* '' */
.icon-icon-chat-alt-2-filled:before {
  content: '\e83b';
} /* '' */
.icon-icon-chat-alt-2-outlined:before {
  content: '\e83c';
} /* '' */
.icon-icon-chat-alt-filled:before {
  content: '\e83d';
} /* '' */
.icon-icon-chat-alt-outlined:before {
  content: '\e83e';
} /* '' */
.icon-icon-chat-filled:before {
  content: '\e83f';
} /* '' */
.icon-icon-chat-outlined:before {
  content: '\e840';
} /* '' */
.icon-icon-check-circle-filled:before {
  content: '\e841';
} /* '' */
.icon-icon-check-circle-outlined:before {
  content: '\e842';
} /* '' */
.icon-icon-check:before {
  content: '\e843';
} /* '' */
.icon-icon-chevron-double-down:before {
  content: '\e844';
} /* '' */
.icon-icon-chevron-double-left:before {
  content: '\e845';
} /* '' */
.icon-icon-chevron-double-right:before {
  content: '\e846';
} /* '' */
.icon-icon-chevron-double-up:before {
  content: '\e847';
} /* '' */
.icon-icon-chevron-down:before {
  content: '\e848';
} /* '' */
.icon-icon-chevron-left:before {
  content: '\e849';
} /* '' */
.icon-icon-chevron-right:before {
  content: '\e84a';
} /* '' */
.icon-icon-chevron-up:before {
  content: '\e84b';
} /* '' */
.icon-icon-chip-filled:before {
  content: '\e84c';
} /* '' */
.icon-icon-chip-outlined:before {
  content: '\e84d';
} /* '' */
.icon-icon-clipboard-check-filled:before {
  content: '\e84e';
} /* '' */
.icon-icon-clipboard-check-outlined:before {
  content: '\e84f';
} /* '' */
.icon-icon-clipboard-copy-filled:before {
  content: '\e850';
} /* '' */
.icon-icon-clipboard-copy-outlined:before {
  content: '\e851';
} /* '' */
.icon-icon-clipboard-filled:before {
  content: '\e852';
} /* '' */
.icon-icon-clipboard-list-filled:before {
  content: '\e853';
} /* '' */
.icon-icon-clipboard-list-outlined:before {
  content: '\e854';
} /* '' */
.icon-icon-clipboard-outlined:before {
  content: '\e855';
} /* '' */
.icon-icon-clock-filled:before {
  content: '\e856';
} /* '' */
.icon-icon-clock-outlined:before {
  content: '\e857';
} /* '' */
.icon-icon-cloud-download-filled:before {
  content: '\e858';
} /* '' */
.icon-icon-cloud-download-outlined:before {
  content: '\e859';
} /* '' */
.icon-icon-cloud-filled:before {
  content: '\e85a';
} /* '' */
.icon-icon-cloud-outlined:before {
  content: '\e85b';
} /* '' */
.icon-icon-cloud-upload-filled:before {
  content: '\e85c';
} /* '' */
.icon-icon-cloud-upload-outlined:before {
  content: '\e85d';
} /* '' */
.icon-icon-code:before {
  content: '\e85e';
} /* '' */
.icon-icon-cog-filled:before {
  content: '\e85f';
} /* '' */
.icon-icon-cog-outlined:before {
  content: '\e860';
} /* '' */
.icon-icon-collection-filled:before {
  content: '\e861';
} /* '' */
.icon-icon-collection-outlined:before {
  content: '\e862';
} /* '' */
.icon-icon-color-swatch-filled:before {
  content: '\e863';
} /* '' */
.icon-icon-color-swatch-outlined:before {
  content: '\e864';
} /* '' */
.icon-icon-credit-card-filled:before {
  content: '\e865';
} /* '' */
.icon-icon-credit-card-outlined:before {
  content: '\e866';
} /* '' */
.icon-icon-cube-filled:before {
  content: '\e867';
} /* '' */
.icon-icon-cube-outlined:before {
  content: '\e868';
} /* '' */
.icon-icon-cube-transparent:before {
  content: '\e869';
} /* '' */
.icon-icon-currency-dollar-filled:before {
  content: '\e86a';
} /* '' */
.icon-icon-currency-dollar-outlined:before {
  content: '\e86b';
} /* '' */
.icon-icon-currency-euro-filled:before {
  content: '\e86c';
} /* '' */
.icon-icon-currency-euro-outlined:before {
  content: '\e86d';
} /* '' */
.icon-icon-currency-pound-filled:before {
  content: '\e86e';
} /* '' */
.icon-icon-currency-pound-outlined:before {
  content: '\e86f';
} /* '' */
.icon-icon-cursor-click-filled:before {
  content: '\e870';
} /* '' */
.icon-icon-cursor-click-outlined:before {
  content: '\e871';
} /* '' */
.icon-icon-database-filled:before {
  content: '\e872';
} /* '' */
.icon-icon-database-outlined:before {
  content: '\e873';
} /* '' */
.icon-icon-desktop-computer-filled:before {
  content: '\e874';
} /* '' */
.icon-icon-desktop-computer-outlined:before {
  content: '\e875';
} /* '' */
.icon-icon-device-mobile-filled:before {
  content: '\e876';
} /* '' */
.icon-icon-device-mobile-outlined:before {
  content: '\e877';
} /* '' */
.icon-icon-device-tablet-filled:before {
  content: '\e878';
} /* '' */
.icon-icon-device-tablet-outlined:before {
  content: '\e879';
} /* '' */
.icon-icon-document-add-filled:before {
  content: '\e87a';
} /* '' */
.icon-icon-document-add-outlined:before {
  content: '\e87b';
} /* '' */
.icon-icon-document-download-filled:before {
  content: '\e87c';
} /* '' */
.icon-icon-document-download-outlined:before {
  content: '\e87d';
} /* '' */
.icon-icon-document-duplicate-filled:before {
  content: '\e87e';
} /* '' */
.icon-icon-document-duplicate-outlined:before {
  content: '\e87f';
} /* '' */
.icon-icon-document-filled:before {
  content: '\e880';
} /* '' */
.icon-icon-document-outlined:before {
  content: '\e881';
} /* '' */
.icon-icon-document-remove-filled:before {
  content: '\e882';
} /* '' */
.icon-icon-document-remove-outlined:before {
  content: '\e883';
} /* '' */
.icon-icon-document-report-filled:before {
  content: '\e884';
} /* '' */
.icon-icon-document-report-outlined:before {
  content: '\e885';
} /* '' */
.icon-icon-document-search-filled:before {
  content: '\e886';
} /* '' */
.icon-icon-document-search-outlined:before {
  content: '\e887';
} /* '' */
.icon-icon-document-text-filled:before {
  content: '\e888';
} /* '' */
.icon-icon-document-text-outlined:before {
  content: '\e889';
} /* '' */
.icon-icon-dots-circle-horizontal-filled:before {
  content: '\e88a';
} /* '' */
.icon-icon-dots-circle-horizontal-outlined:before {
  content: '\e88b';
} /* '' */
.icon-icon-dots-horizontal:before {
  content: '\e88c';
} /* '' */
.icon-icon-dots-vertical:before {
  content: '\e88d';
} /* '' */
.icon-icon-download:before {
  content: '\e88e';
} /* '' */
.icon-icon-duplicate-filled:before {
  content: '\e88f';
} /* '' */
.icon-icon-duplicate-outlined:before {
  content: '\e890';
} /* '' */
.icon-icon-emoji-happy-filled:before {
  content: '\e891';
} /* '' */
.icon-icon-emoji-happy-outlined:before {
  content: '\e892';
} /* '' */
.icon-icon-emoji-sad-filled:before {
  content: '\e893';
} /* '' */
.icon-icon-emoji-sad-outlined:before {
  content: '\e894';
} /* '' */
.icon-icon-exclamation-circle-filled:before {
  content: '\e895';
} /* '' */
.icon-icon-exclamation-circle-outlined:before {
  content: '\e896';
} /* '' */
.icon-icon-exclamation-filled:before {
  content: '\e897';
} /* '' */
.icon-icon-exclamation-outlined:before {
  content: '\e898';
} /* '' */
.icon-icon-external-link:before {
  content: '\e899';
} /* '' */
.icon-icon-eye-filled:before {
  content: '\e89a';
} /* '' */
.icon-icon-eye-off-filled:before {
  content: '\e89b';
} /* '' */
.icon-icon-eye-off-outlined:before {
  content: '\e89c';
} /* '' */
.icon-icon-eye-outlined:before {
  content: '\e89d';
} /* '' */
.icon-icon-fast-forward-filled:before {
  content: '\e89e';
} /* '' */
.icon-icon-fast-forward-outlined:before {
  content: '\e89f';
} /* '' */
.icon-icon-film:before {
  content: '\e8a0';
} /* '' */
.icon-icon-filter-filled:before {
  content: '\e8a1';
} /* '' */
.icon-icon-filter-outlined:before {
  content: '\e8a2';
} /* '' */
.icon-icon-finger-print:before {
  content: '\e8a3';
} /* '' */
.icon-icon-fire-filled:before {
  content: '\e8a4';
} /* '' */
.icon-icon-fire-outlined:before {
  content: '\e8a5';
} /* '' */
.icon-icon-flag-filled:before {
  content: '\e8a6';
} /* '' */
.icon-icon-flag-outlined:before {
  content: '\e8a7';
} /* '' */
.icon-icon-folder-add-filled:before {
  content: '\e8a8';
} /* '' */
.icon-icon-folder-add-outlined:before {
  content: '\e8a9';
} /* '' */
.icon-icon-folder-download-filled:before {
  content: '\e8aa';
} /* '' */
.icon-icon-folder-download-outlined:before {
  content: '\e8ab';
} /* '' */
.icon-icon-folder-filled:before {
  content: '\e8ac';
} /* '' */
.icon-icon-folder-open-filled:before {
  content: '\e8ad';
} /* '' */
.icon-icon-folder-open-outlined:before {
  content: '\e8ae';
} /* '' */
.icon-icon-folder-outlined:before {
  content: '\e8af';
} /* '' */
.icon-icon-folder-remove-filled:before {
  content: '\e8b0';
} /* '' */
.icon-icon-folder-remove-outlined:before {
  content: '\e8b1';
} /* '' */
.icon-icon-gift-filled:before {
  content: '\e8b2';
} /* '' */
.icon-icon-gift-outlined:before {
  content: '\e8b3';
} /* '' */
.icon-icon-globe-alt-outlined:before {
  content: '\e8b4';
} /* '' */
.icon-icon-globe-filled:before {
  content: '\e8b5';
} /* '' */
.icon-icon-globe-outlined:before {
  content: '\e8b6';
} /* '' */
.icon-icon-hand-filled:before {
  content: '\e8b7';
} /* '' */
.icon-icon-hand-outlined:before {
  content: '\e8b8';
} /* '' */
.icon-icon-hashtag:before {
  content: '\e8b9';
} /* '' */
.icon-icon-heart-filled:before {
  content: '\e8ba';
} /* '' */
.icon-icon-heart-outlined:before {
  content: '\e8bb';
} /* '' */
.icon-icon-home-filled:before {
  content: '\e8bc';
} /* '' */
.icon-icon-home-outlined:before {
  content: '\e8bd';
} /* '' */
.icon-icon-identification-filled:before {
  content: '\e8be';
} /* '' */
.icon-icon-identification-outlined:before {
  content: '\e8bf';
} /* '' */
.icon-icon-inbox-filled:before {
  content: '\e8c0';
} /* '' */
.icon-icon-inbox-in-filled:before {
  content: '\e8c1';
} /* '' */
.icon-icon-inbox-in-outlined:before {
  content: '\e8c2';
} /* '' */
.icon-icon-inbox-outlined:before {
  content: '\e8c3';
} /* '' */
.icon-icon-information-circle-filled:before {
  content: '\e8c4';
} /* '' */
.icon-icon-information-circle-outlined:before {
  content: '\e8c5';
} /* '' */
.icon-icon-key-filled:before {
  content: '\e8c6';
} /* '' */
.icon-icon-key-outlined:before {
  content: '\e8c7';
} /* '' */
.icon-icon-library-filled:before {
  content: '\e8c8';
} /* '' */
.icon-icon-library-outlined:before {
  content: '\e8c9';
} /* '' */
.icon-icon-light-bulb-filled:before {
  content: '\e8ca';
} /* '' */
.icon-icon-light-bulb-outlined:before {
  content: '\e8cb';
} /* '' */
.icon-icon-lightning-bolt-filled:before {
  content: '\e8cc';
} /* '' */
.icon-icon-lightning-bolt-outlined:before {
  content: '\e8cd';
} /* '' */
.icon-icon-link:before {
  content: '\e8ce';
} /* '' */
.icon-icon-location-marker-filled:before {
  content: '\e8cf';
} /* '' */
.icon-icon-location-marker-outlined:before {
  content: '\e8d0';
} /* '' */
.icon-icon-lock-closed-filled:before {
  content: '\e8d1';
} /* '' */
.icon-icon-lock-closed-outlined:before {
  content: '\e8d2';
} /* '' */
.icon-icon-lock-open-filled:before {
  content: '\e8d3';
} /* '' */
.icon-icon-lock-open-outlined:before {
  content: '\e8d4';
} /* '' */
.icon-icon-login-outlined:before {
  content: '\e8d5';
} /* '' */
.icon-icon-logout-outlined:before {
  content: '\e8d6';
} /* '' */
.icon-icon-mail-filled:before {
  content: '\e8d7';
} /* '' */
.icon-icon-mail-open-filled:before {
  content: '\e8d8';
} /* '' */
.icon-icon-mail-open-outlined:before {
  content: '\e8d9';
} /* '' */
.icon-icon-mail-outlined:before {
  content: '\e8da';
} /* '' */
.icon-icon-map-filled:before {
  content: '\e8db';
} /* '' */
.icon-icon-map-outlined:before {
  content: '\e8dc';
} /* '' */
.icon-icon-menu-alt-1:before {
  content: '\e8dd';
} /* '' */
.icon-icon-menu-alt-2:before {
  content: '\e8de';
} /* '' */
.icon-icon-menu-alt-3:before {
  content: '\e8df';
} /* '' */
.icon-icon-menu-alt-4:before {
  content: '\e8e0';
} /* '' */
.icon-icon-menu:before {
  content: '\e8e1';
} /* '' */
.icon-icon-microphone-filled:before {
  content: '\e8e2';
} /* '' */
.icon-icon-microphone-outlined:before {
  content: '\e8e3';
} /* '' */
.icon-icon-minus-circle-filled:before {
  content: '\e8e4';
} /* '' */
.icon-icon-minus-circle-outlined:before {
  content: '\e8e5';
} /* '' */
.icon-icon-minus:before {
  content: '\e8e6';
} /* '' */
.icon-icon-moon-filled:before {
  content: '\e8e7';
} /* '' */
.icon-icon-moon-outlined:before {
  content: '\e8e8';
} /* '' */
.icon-icon-music-note-filled:before {
  content: '\e8e9';
} /* '' */
.icon-icon-music-note-outlined:before {
  content: '\e8ea';
} /* '' */
.icon-icon-newspaper-filled:before {
  content: '\e8eb';
} /* '' */
.icon-icon-newspaper-outlined:before {
  content: '\e8ec';
} /* '' */
.icon-icon-office-building-filled:before {
  content: '\e8ed';
} /* '' */
.icon-icon-office-building-outlined:before {
  content: '\e8ee';
} /* '' */
.icon-icon-paper-airplane-filled:before {
  content: '\e8ef';
} /* '' */
.icon-icon-paper-airplane-outlined:before {
  content: '\e8f0';
} /* '' */
.icon-icon-paper-clip:before {
  content: '\e8f1';
} /* '' */
.icon-icon-pause-filled:before {
  content: '\e8f2';
} /* '' */
.icon-icon-pause-outlined:before {
  content: '\e8f3';
} /* '' */
.icon-icon-pencil-alt-2-filled:before {
  content: '\e8f4';
} /* '' */
.icon-icon-pencil-alt-2-outlined:before {
  content: '\e8f5';
} /* '' */
.icon-icon-pencil-alt-filled:before {
  content: '\e8f6';
} /* '' */
.icon-icon-pencil-alt-outlined:before {
  content: '\e8f7';
} /* '' */
.icon-icon-pencil-filled:before {
  content: '\e8f8';
} /* '' */
.icon-icon-pencil-outlined:before {
  content: '\e8f9';
} /* '' */
.icon-icon-phone-filled:before {
  content: '\e8fa';
} /* '' */
.icon-icon-phone-incoming-filled:before {
  content: '\e8fb';
} /* '' */
.icon-icon-phone-incoming-outlined:before {
  content: '\e8fc';
} /* '' */
.icon-icon-phone-missed-call-filled:before {
  content: '\e8fd';
} /* '' */
.icon-icon-phone-missed-call-outlined:before {
  content: '\e8fe';
} /* '' */
.icon-icon-phone-outgoing-filled:before {
  content: '\e8ff';
} /* '' */
.icon-icon-phone-outgoing-outlined:before {
  content: '\e900';
} /* '' */
.icon-icon-phone-outlined:before {
  content: '\e901';
} /* '' */
.icon-icon-photograph-filled:before {
  content: '\e902';
} /* '' */
.icon-icon-photograph-outlined:before {
  content: '\e903';
} /* '' */
.icon-icon-play-filled:before {
  content: '\e904';
} /* '' */
.icon-icon-play-outlined:before {
  content: '\e905';
} /* '' */
.icon-icon-plus-circle-filled:before {
  content: '\e906';
} /* '' */
.icon-icon-plus-circle-outlined:before {
  content: '\e907';
} /* '' */
.icon-icon-plus:before {
  content: '\e908';
} /* '' */
.icon-icon-presentation-chart-bar-filled:before {
  content: '\e909';
} /* '' */
.icon-icon-presentation-chart-bar-outlined:before {
  content: '\e90a';
} /* '' */
.icon-icon-presentation-chart-line-filled:before {
  content: '\e90b';
} /* '' */
.icon-icon-presentation-chart-line-outlined:before {
  content: '\e90c';
} /* '' */
.icon-icon-printer-filled:before {
  content: '\e90d';
} /* '' */
.icon-icon-printer-outlined:before {
  content: '\e90e';
} /* '' */
.icon-icon-puzzle-filled:before {
  content: '\e90f';
} /* '' */
.icon-icon-puzzle-outlined:before {
  content: '\e910';
} /* '' */
.icon-icon-qrcode:before {
  content: '\e911';
} /* '' */
.icon-icon-question-mark-circle-filled:before {
  content: '\e912';
} /* '' */
.icon-icon-question-mark-circle-outlined:before {
  content: '\e913';
} /* '' */
.icon-icon-receipt-refund-filled:before {
  content: '\e914';
} /* '' */
.icon-icon-receipt-refund-outlined:before {
  content: '\e915';
} /* '' */
.icon-icon-receipt-tax-filled:before {
  content: '\e916';
} /* '' */
.icon-icon-receipt-tax-outlined:before {
  content: '\e917';
} /* '' */
.icon-icon-refresh:before {
  content: '\e918';
} /* '' */
.icon-icon-reply:before {
  content: '\e919';
} /* '' */
.icon-icon-rewind-filled:before {
  content: '\e91a';
} /* '' */
.icon-icon-rewind-outlined:before {
  content: '\e91b';
} /* '' */
.icon-icon-rss:before {
  content: '\e91c';
} /* '' */
.icon-icon-save-filled:before {
  content: '\e91d';
} /* '' */
.icon-icon-save-outlined:before {
  content: '\e91e';
} /* '' */
.icon-icon-scale:before {
  content: '\e91f';
} /* '' */
.icon-icon-scissors:before {
  content: '\e920';
} /* '' */
.icon-icon-search-circle-filled:before {
  content: '\e921';
} /* '' */
.icon-icon-search-circle-outlined:before {
  content: '\e922';
} /* '' */
.icon-icon-search:before {
  content: '\e923';
} /* '' */
.icon-icon-selector:before {
  content: '\e924';
} /* '' */
.icon-icon-server-filled:before {
  content: '\e925';
} /* '' */
.icon-icon-server-outlined:before {
  content: '\e926';
} /* '' */
.icon-icon-share-filled:before {
  content: '\e927';
} /* '' */
.icon-icon-share-outlined:before {
  content: '\e928';
} /* '' */
.icon-icon-shield-check-filled:before {
  content: '\e929';
} /* '' */
.icon-icon-shield-check-outlined:before {
  content: '\e92a';
} /* '' */
.icon-icon-shield-exclamation-filled:before {
  content: '\e92b';
} /* '' */
.icon-icon-shield-exclamation-outlined:before {
  content: '\e92c';
} /* '' */
.icon-icon-shopping-bag-filled:before {
  content: '\e92d';
} /* '' */
.icon-icon-shopping-bag-outlined:before {
  content: '\e92e';
} /* '' */
.icon-icon-shopping-cart-filled:before {
  content: '\e92f';
} /* '' */
.icon-icon-shopping-cart-outlined:before {
  content: '\e930';
} /* '' */
.icon-icon-sort-ascending:before {
  content: '\e931';
} /* '' */
.icon-icon-sort-descending:before {
  content: '\e932';
} /* '' */
.icon-icon-sparkles-filled:before {
  content: '\e933';
} /* '' */
.icon-icon-sparkles-outlined:before {
  content: '\e934';
} /* '' */
.icon-icon-speakerphone-filled:before {
  content: '\e935';
} /* '' */
.icon-icon-speakerphone-outlined:before {
  content: '\e936';
} /* '' */
.icon-icon-star-filled:before {
  content: '\e937';
} /* '' */
.icon-icon-star-outlined:before {
  content: '\e938';
} /* '' */
.icon-icon-status-offline:before {
  content: '\e939';
} /* '' */
.icon-icon-status-online:before {
  content: '\e93a';
} /* '' */
.icon-icon-stop-filled:before {
  content: '\e93b';
} /* '' */
.icon-icon-stop-outlined:before {
  content: '\e93c';
} /* '' */
.icon-icon-sun-filled:before {
  content: '\e93d';
} /* '' */
.icon-icon-sun-outlined:before {
  content: '\e93e';
} /* '' */
.icon-icon-support-filled:before {
  content: '\e93f';
} /* '' */
.icon-icon-support-outlined:before {
  content: '\e940';
} /* '' */
.icon-icon-switch-horizontal:before {
  content: '\e941';
} /* '' */
.icon-icon-switch-vertical:before {
  content: '\e942';
} /* '' */
.icon-icon-table-filled:before {
  content: '\e943';
} /* '' */
.icon-icon-table-outlined:before {
  content: '\e944';
} /* '' */
.icon-icon-tag-filled:before {
  content: '\e945';
} /* '' */
.icon-icon-tag-outlined:before {
  content: '\e946';
} /* '' */
.icon-icon-template-filled:before {
  content: '\e947';
} /* '' */
.icon-icon-template-outlined:before {
  content: '\e948';
} /* '' */
.icon-icon-terminal-filled:before {
  content: '\e949';
} /* '' */
.icon-icon-terminal-outlined:before {
  content: '\e94a';
} /* '' */
.icon-icon-thumb-down-filled:before {
  content: '\e94b';
} /* '' */
.icon-icon-thumb-down-outlined:before {
  content: '\e94c';
} /* '' */
.icon-icon-thumb-up-filled:before {
  content: '\e94d';
} /* '' */
.icon-icon-thumb-up-outlined:before {
  content: '\e94e';
} /* '' */
.icon-icon-ticket-filled:before {
  content: '\e94f';
} /* '' */
.icon-icon-ticket-outlined:before {
  content: '\e950';
} /* '' */
.icon-icon-translate:before {
  content: '\e951';
} /* '' */
.icon-icon-trash-filled:before {
  content: '\e952';
} /* '' */
.icon-icon-trash-outlined:before {
  content: '\e953';
} /* '' */
.icon-icon-trending-down:before {
  content: '\e954';
} /* '' */
.icon-icon-trending-up:before {
  content: '\e955';
} /* '' */
.icon-icon-truck-filled:before {
  content: '\e956';
} /* '' */
.icon-icon-truck-outlined:before {
  content: '\e957';
} /* '' */
.icon-icon-upload:before {
  content: '\e958';
} /* '' */
.icon-icon-user-add-filled:before {
  content: '\e959';
} /* '' */
.icon-icon-user-add-outlined:before {
  content: '\e95a';
} /* '' */
.icon-icon-user-circle-filled:before {
  content: '\e95b';
} /* '' */
.icon-icon-user-circle-outlined:before {
  content: '\e95c';
} /* '' */
.icon-icon-user-filled:before {
  content: '\e95d';
} /* '' */
.icon-icon-user-group-filled:before {
  content: '\e95e';
} /* '' */
.icon-icon-user-group-outlined:before {
  content: '\e95f';
} /* '' */
.icon-icon-user-outlined:before {
  content: '\e960';
} /* '' */
.icon-icon-user-remove-filled:before {
  content: '\e961';
} /* '' */
.icon-icon-user-remove-outlined:before {
  content: '\e962';
} /* '' */
.icon-icon-users-filled:before {
  content: '\e963';
} /* '' */
.icon-icon-users-outlined:before {
  content: '\e964';
} /* '' */
.icon-icon-variable:before {
  content: '\e965';
} /* '' */
.icon-icon-video-camera-filled:before {
  content: '\e966';
} /* '' */
.icon-icon-video-camera-outlined:before {
  content: '\e967';
} /* '' */
.icon-icon-view-boards-filled:before {
  content: '\e968';
} /* '' */
.icon-icon-view-boards-outlined:before {
  content: '\e969';
} /* '' */
.icon-icon-view-grid-add-filled:before {
  content: '\e96a';
} /* '' */
.icon-icon-view-grid-add-outlined:before {
  content: '\e96b';
} /* '' */
.icon-icon-view-grid-filled:before {
  content: '\e96c';
} /* '' */
.icon-icon-view-grid-outlined:before {
  content: '\e96d';
} /* '' */
.icon-icon-view-list:before {
  content: '\e96e';
} /* '' */
.icon-icon-volume-off-filled:before {
  content: '\e96f';
} /* '' */
.icon-icon-volume-off-outlined:before {
  content: '\e970';
} /* '' */
.icon-icon-volume-up-filled:before {
  content: '\e971';
} /* '' */
.icon-icon-volume-up-outlined:before {
  content: '\e972';
} /* '' */
.icon-icon-wifi:before {
  content: '\e973';
} /* '' */
.icon-icon-x-circle-filled:before {
  content: '\e974';
} /* '' */
.icon-icon-x-circle-outlined:before {
  content: '\e975';
} /* '' */
.icon-icon-x:before {
  content: '\e976';
} /* '' */
.icon-icon-zoom-in-outlined:before {
  content: '\e977';
} /* '' */
.icon-icon-zoom-out-outlined:before {
  content: '\e978';
} /* '' */
