.active-status-indicator {
  width: rem(6px);
  height: rem(6px);
  margin: rem(7px) rem(8px) rem(5px) rem(0px);
  background-color: #25aa5e;
  border-radius: 50%;
}

.inactive-status-indicator {
  width: rem(6px);
  height: rem(6px);
  margin: rem(7px) rem(8px) rem(5px) rem(0px);
  background-color: #f54828;
  border-radius: 50%;
}
