.react-calendar {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 21px;
  padding-right: 21px;
  margin-left: 24px;
  border-radius: 4px;
  border: 1px solid#cdd0d7 !important;
  background-color: #fff;
  font-family: "Muli Regular", sans-serif !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 125% !important;
  width: 500px;
}

.react-calendar__tile--now {
  background-color: #e9efff !important;
  color: #1e5eff !important;
  border-radius: 4px !important;
}

.react-calendar__navigation__arrow {
  font-size: 30px;
  color: #1e5eff;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  text-align: center;
  font-family: "Muli Regular", sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
}

.react-calendar__tile {
  width: 48px;
  height: 48px;
}

.react-calendar__month-view__weekdays__weekday {
  color: #394561;
  text-align: center;
  font-family: "Muli Regular", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  display: flex;
  width: 48px;
  height: 32px;
  padding: 7px 13px 7px 11px;
  justify-content: center;
  align-items: center;
}

abbr[title] {
  text-decoration: none !important;
  cursor: none !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #394561;
  font-size: 14px;
  font-style: normal;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #b5b9c4;
}
