$spacings: (
  "0": rem(0px),
  "4": rem(4px),
  "8": rem(8px),
  "12": rem(12px),
  "16": rem(16px),
  "20": rem(20px),
  "24": rem(24px),
  "32": rem(32px),
  "48": rem(48px),
  "72": rem(72px),
);

$paddings: "pl", "pr", "pt", "pb";
$margins: "ml", "mr", "mt", "mb";

@mixin getStyle($var, $size) {
  @if $var == "pl" {
    padding-left: $size !important;
  }

  @if $var == "pr" {
    padding-right: $size !important;
  }

  @if $var == "pt" {
    padding-top: $size !important;
  }

  @if $var == "pb" {
    padding-bottom: $size !important;
  }

  @if $var == "mr" {
    margin-right: $size !important;
  }

  @if $var == "ml" {
    margin-left: $size !important;
  }

  @if $var == "mt" {
    margin-top: $size !important;
  }

  @if $var == "mb" {
    margin-bottom: $size !important;
  }
}

/* Generates all padding spacing classes */
@each $var in $paddings {
  @each $name, $size in $spacings {
    .#{$var}-#{$name} {
      @include getStyle($var, $size);
    }
  }
}

/* Generates all margin spacing classes */
@each $var in $margins {
  @each $name, $size in $spacings {
    .#{$var}-#{$name} {
      @include getStyle($var, $size);
    }
  }
}
