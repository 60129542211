$colors: (
  "light-bg": #ffffff,
  "light-grey-bg": #f7f8f9,
  "dark-bg": #2c3956,
);

/* Generates all color classes for palette*/
@each $name, $hex in $colors {
  .#{$name} {
    background-color: $hex;
  }
}

.color-box {
  border-radius: 4px;
  padding-bottom: 10%;
  margin: 5px;
}
