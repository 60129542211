.bg-dark {
  background-color: #2c3957 !important;
}

.navbar-top-menu-icon {
  color: #6a7388;
  margin-right: rem(28px);
}

.navbar-container {
  padding-left: rem(0px);
  padding-right: rem(0px);
}

.fusion-logo-light {
  width: 108px;
  height: 28px;
  margin: 4px 48px 4px 0;
  object-fit: contain;
}

.top-navbar {
  height: $topbar-height;
  padding: rem(18px) rem(32px) rem(19px) rem(28px);
  border-bottom: solid 1px #e6e7eb;

  &-content-left {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
}

.top-menu-bar-divide {
  height: 1px;
  background-color: #e6e7eb;
}

.menu-profile {
  padding-top: rem(12px);
  padding-bottom: rem(13px);
  padding-left: rem(16px);
  padding-right: rem(16px);
  border-radius: 4px;
  background-color: #f7f8f9;
}

.nav-link {
  @extend .nav-link;
  cursor: pointer;
  padding: 0px 0px 0px 0px !important;
}

.nav-active {
  color: #ffffff !important;
  background-color: #142243 !important;
  border-radius: 4px;
  cursor: pointer;
}

.custom.dropdown-toggle::after {
  display: none;
}

.custom.dropdown-toggle > svg {
  color: #6a7388;
  margin-left: rem(6px);
}

/* .dropdown-icon-margin {
  margin-right: rem(6px);
} */

.dropdown-nav {
  border-radius: rem(4px);
  box-shadow: 0 1px 2px 0 rgba(7, 22, 57, 0.06);
  border: solid 1px #e6e7eb;
  background-color: #ffffff;
  padding: rem(0px);
  min-width: rem(258.56px);
}

#notificationCount {
  background: #ff671b;
  color: #fff;
  padding: 3px;
  vertical-align: top;
  margin-left: -15px;
  width: 18px;
  height: 18px;
}

.notifiction-badge {
  @extend .badge;
  border-radius: 50%;
  margin-top: -4px;
}

.navbar-notification {
  margin-right: rem(12px);
  color: white;
  cursor: pointer;
}

.nav-avatar {
  cursor: pointer;
}

.side-navbar-expanded {
  width: rem(268px);
}

.side-navbar-minimised {
  width: rem(80px);
  height: 100%;
}

.side-navbar-padding {
  padding-top: rem(32px);
  padding-bottom: rem(24px);
}
.nav-column-padding {
  padding-right: rem(20px);
  padding-left: rem(20px);
}

.nav-item {
  padding: rem(8px) rem(12px);
  cursor: pointer;
}

.top-nav-item {
  padding: 0.5rem 0rem 0.5rem 0.75rem;
}

.nav-item-right > span:hover {
  color: white;
}

.nav-item-right {
  margin-right: rem(24px);
}

.nav-item-side {
  padding: 0rem 0.5rem;
  margin-right: rem(0px);
  margin-bottom: rem(4px);
  line-height: rem(37px);
  width: 100%;
  height: rem(40px);
  cursor: pointer;
}

.nav-states:hover {
  & > * {
    color: #ffffff !important;
    font-weight: bold !important;
  }
}

.navbar-menu-icon > svg {
  color: #e6e8eb;
  cursor: pointer;
}

.nav-icon-spacing {
  line-height: rem(0px);
}

.nav-button-text-spacing {
  margin-left: rem(12px);
  vertical-align: middle;
}

.nav-heading {
  margin-top: rem(20px);
  margin-bottom: rem(8px);
}

.navbar-minimised-spacer {
  height: rem(52px);
}

.nav-divider {
  width: rem(222px);
  height: rem(1px);
  margin: rem(28px) rem(0);
  background-color: #374159;
}

.nav-icon-inactive {
  color: #cdd0d7 !important;
}

.menu-item-default {
  color: #cdd0d7;
}
.menu-item:hover {
  color: #ffffff;
}

.menu-item:disabled {
  color: #6a7388;
}

.menu-item-text-active {
  color: #ffffff;
}

.menu-item-disabled {
  color: #6a7388;
}

.menu-item {
  background-color: #132243 !important;
  border-radius: 4px;
  cursor: pointer;
}

.menu-item-active {
  color: #ffffff;
}

.nav-icon-active {
  color: white !important;
}

.nav-icon-spacing svg {
  fill: currentColor;
}

.list-item-background-light {
  background-color: #ffffff;
}

.list-item-background-dark {
  background-color: #f7f8f9;
}

.dropdown-menu-item {
  border-bottom: rem(1px) solid #e6e7eb;
}

.list-item-top:first-child {
  border-radius: rem(5px) rem(5px) rem(0px) rem(0px);
  background-color: #f7f8f9;
  padding-top: rem(12px);
  padding-bottom: rem(12px);
}

.list-item:last-child {
  border-radius: rem(0px) rem(0px) rem(5px) rem(5px);
}

.team-initial-avatar {
  margin-right: rem(12px);
  width: rem(30px);
  height: rem(30px);
  object-fit: contain;
  border-radius: rem(8px);
}

.team-initials {
  letter-spacing: rem(1.5px);
  font-size: rem(11px);
}

.team-switch {
  position: static;
  margin-right: rem(19px);
}

.sub-menu-item > .team-avatar {
  margin-right: rem(12px);
}

.list-heading {
  padding: rem(12px) rem(0px) rem(8px) rem(16px);
}

.menu-list-item {
  padding: rem(8px) rem(0px) rem(11px) rem(16px);
}

.current-team {
  padding: rem(8px) rem(0px) rem(8px) rem(16px);
  min-width: rem(260px);
}

.current-active-team {
  padding-bottom: rem(16px);
}

.current-team-chevron {
  margin-right: rem(12px);
}

.dropdown-menu-list {
  padding: rem(4px) rem(0px);
}

.side-dropdown-menu {
  padding-top: rem(0px);
  padding-bottom: rem(0px);
  top: rem(10px);
}

.switch-information-chevron {
  margin-right: rem(12px);
}

.sidebar-show {
  display: block;
}
.sidebar-hide {
  display: none;
}

.nav-bar-dropdown-button {
  //margin-bottom: rem(20px) !important;
  display: flex;
  cursor: pointer;
}

.sidebar-dropdown-icon {
  height: rem(40px);
  width: rem(40px);
}

.sidebar-dropdown-text {
  padding: 10px 0px 10px 10px;
}

.sidebar-dropdown-icon {
  height: rem(40px);
  width: rem(40px);
}

.sidebar-dropdown-button-text {
  margin-left: 0.75rem;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.sidebar-dropdown-arrow-icon {
  align-self: center;
  margin-left: auto;
  margin-bottom: 12px;
}

.sidebar-width-expanded {
  max-width: rem(260px);
}
.sidebar-width-minimised {
  max-width: rem(80px);
}

.sidebar-offset-margin-expanded {
  margin-left: rem(260px);
}

.sidebar-offset-margin-minimised {
  margin-left: rem(80px);
}

.navbar-bg-color {
  background-color: #ffffff;
}

.dropdown-option {
  padding: 6px;
  padding-left: 8px;
  margin: 6px;
  margin-left: 25px;
  border-radius: 3px;
  line-height: 1rem;
  width: 80%;
  cursor: pointer
}

.dropdown-option:hover {
  background: #2C3957 !important
}

#sidebarMenu::-webkit-scrollbar-thumb {
  background: #071639 !important;
}

#sticky-footer-top-section {
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 1px 2px 0px #0716390f;
  border-top: #394561 solid 1px;
  top: 75%;
  height: 25%;
  background: #2c3956;
  list-style: none;
  padding: 20px;
}

#sticky-footer-bottom-section {
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 1px 2px 0px #0716390f;
  border-top: #6A7388 solid 1px;
  top: 90%;
  padding-top: 20px;
  padding-right: 20px
}
