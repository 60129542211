.custom-badge {
  border-radius: rem(4px);
  background-color: #f2f3f5;
  padding-left: rem(8px);
  padding-right: rem(8px);
  text-transform: lowercase !important;
  margin-left: rem(4px);
  margin-right: rem(4px);
  margin-bottom: rem(4px);
  display: inline-block;
}
